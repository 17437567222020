import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'redux-form';
import { Button, Modal, TextArea } from '@d-lighted/design-system';
import { FormattedMessage, useIntl } from 'react-intl';

import { Col, Row } from 'components/utils/Helper';
import { events, pushEvent } from 'utils/GTM';
import messages from './i18n/batchVisitorAddModal';

const ModalContentWrapper = styled(Row)`
  width: 100%;
  margin: 0;
  padding: 20px;
  text-align: center;
  color: #314143;
  position: relative;

  & a {
    color: #02bbb5;
    text-decoration: underline;
  }
`;

const ModalHeader = styled.div`
  font-size: 23px;
`;

const ModalCloseIcon = styled.div`
  position: absolute;
  right: -10px;
  text-align: center;
  top: -10px;
  height: 28px;
  width: 28px;
  color: #02bbb5;
  font-size: 23px;
  z-index: 100;
  cursor: pointer;
`;

const ErrorWrapper = styled.div`
  color: #fb3a30;
`;

function BatchVisitorAddModal({
  isOpen,
  batchParseError,
  companyIsHidden,
  toggleModal,
  handleParseVisitors,
}) {
  const intl = useIntl();

  const registerClickLinkHelpAction = () => {
    pushEvent({
      // TODO: Add this when defined
      ...events.onClickLinkHelp('/attendees-batch-reg'),
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      onEscapeKeydown={toggleModal}
      width="480px"
      height="500px"
    >
      <ModalContentWrapper>
        <Col>
          <ModalCloseIcon onClick={() => toggleModal()}>╳</ModalCloseIcon>
          <Row mb={66}>
            <Col>
              <ModalHeader>
                <FormattedMessage {...messages.batchAddTitle} />
              </ModalHeader>
            </Col>
          </Row>
          <Row mb={20}>
            <Col>
              <div>
                <FormattedMessage {...messages.info1} />
              </div>
              <div>
                <FormattedMessage {...messages.info2} />
              </div>
              <div>
                <FormattedMessage {...messages.info3} />
                <a
                  target="_blank"
                  onClick={() => registerClickLinkHelpAction()}
                  href={`${process.env.REACT_APP_SCHEDULING_HELP_ROOT}/attendees-batch-reg`}
                  rel="noreferrer"
                >
                  <FormattedMessage {...messages.infoLink} />
                </a>
              </div>
            </Col>
          </Row>
          <Row mb={20}>
            <Col>
              <Field
                name="pastedVisitors"
                component={TextArea}
                placeholder={
                  companyIsHidden
                    ? intl.formatMessage(messages.placeholder1)
                    : intl.formatMessage(messages.placeholder2)
                }
                type="textarea"
                height="200px"
                autoFocus
              />
              {batchParseError != null && (
                <ErrorWrapper>{batchParseError}</ErrorWrapper>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <Button onClick={() => handleParseVisitors()}>
                <FormattedMessage {...messages.addBtn} />
              </Button>
            </Col>
          </Row>
        </Col>
      </ModalContentWrapper>
    </Modal>
  );
}

BatchVisitorAddModal.defaultProps = {
  batchParseError: null,
};

BatchVisitorAddModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  batchParseError: PropTypes.string,
  companyIsHidden: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleParseVisitors: PropTypes.func.isRequired,
};

export default BatchVisitorAddModal;
