import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Field, change } from 'redux-form';
import { isEmpty } from 'lodash';
import { InputWithLabel } from '@d-lighted/design-system';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';

import { Col, Row } from 'components/utils/Helper';
import { useValidation } from 'utils/validation';
import RequiredMark from 'views/confirmation/modules/RequiredMark';
import BatchVisitorAddModal from 'views/confirmation/modules/BatchVisitorAddModal';
import messages from './i18n/visitorFieldsGroup';

const OptionButton = styled.div`
  cursor: pointer;
  width: fit-content;
  background: none;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #00bbb5;
  margin: 10px 0;

  ${({ disabled }) => {
    if (disabled) {
      return `
        pointer-events: none;
        opacity: 0.3;
        user-select: none;
      `;
    }
    return '';
  }}
`;

const AddParticipantButton = styled(OptionButton)`
  &:after {
    content: '';
    width: 10.3px;
    height: 21px;
    margin: 0 0 0 8px;
    font-family: FontAwesome;
    position: relative;
    top: -3px;
  }
`;

const BatchAddButton = styled(OptionButton)`
  margin: 10px 0;
  &:after {
    content: '';
    width: 10.3px;
    height: 21px;
    margin: 0 0 0 23px;
    font-family: FontAwesome;
  }
`;

const ErrorWrapper = styled.div`
  color: rgb(49, 65, 67);
`;

const Wrapper = styled.div`
  position: relative;
  margin: 4px -10px;
  padding: 4px 10px;
  border-radius: 8px;
  margin-bottom: 10px;

  ${({ index }) => {
    if (index > 0) {
      return `
        background: #e7efef;
      `;
    }
    return '';
  }}
`;

const RemoveIconWrapper = styled.div`
  align-items: center;
  background: white;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  height: 28px;
  justify-content: center;
  position: absolute;
  right: -10px;
  text-align: center;
  top: -10px;
  width: 28px;
  z-index: 5;

  &:hover {
    box-shadow: 0 2px 4px 2px #d9d9d9;
  }
`;

function VisitorFieldsGroup({
  fields,
  companyIsHidden,
  companyIsRequired,
  isMobile,
  pastedVisitors,
  multipleguestsAllowed,
}) {
  const lengthLimit = 50;
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const [batchParseError, setBatchParseError] = useState();
  const intl = useIntl();
  const { email, required } = useValidation();

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const addField = inputData => {
    const data = inputData || {};
    if (fields.length < lengthLimit) {
      fields.push(data);
    }
  };

  const removeField = index => {
    fields.remove(index);
  };

  const batchPastedVisitorsValidation = value => {
    if (isEmpty(value)) return false;

    /* eslint-disable max-len,no-irregular-whitespace */
    const regex = companyIsHidden
      ? /^(\S+([　| ]+\S+)*)(\t{1})(\S+([　| ]+\S+)*)$/
      : /^(\S+([　| ]+\S+)*)(\t{1})(\S+([　| ]+\S+)*)(\t{1})(\S+([　| ]+\S+)*)(\n*)$/;
    /* eslint-enable max-len,no-irregular-whitespace */

    const isRegexCleared = !value.split('\n').filter(v => !regex.test(v))
      .length;
    if (!isRegexCleared) {
      return companyIsHidden
        ? intl.formatMessage(messages.instruction1)
        : intl.formatMessage(messages.instruction2);
    }

    return null;
  };

  const handleParseVisitors = () => {
    const validatedPastedVisitors =
      batchPastedVisitorsValidation(pastedVisitors);
    if (validatedPastedVisitors != null) {
      return setBatchParseError(validatedPastedVisitors);
    }
    const parsedFormValue = pastedVisitors
      .split(/\n/)
      .map(line => line.split(/\t/));

    const currentFields = fields.getAll();

    // Actions over `fields` dispatch a redux action everytime so `fields.length` can not
    // be trust after this point, we need to track insertions/deletions ourselves
    let currentLength = currentFields.length;

    // Removing empty and not used visitors
    for (let i = currentFields.length - 1; i >= 0; i -= 1) {
      const { name, companyName, email: fieldEmail } = currentFields[i];
      if (isEmpty(name) && isEmpty(companyName) && isEmpty(fieldEmail)) {
        currentLength -= 1;
        fields.remove(i);
      }
    }

    parsedFormValue.forEach(parsedVisitor => {
      if (currentLength >= lengthLimit) return;

      const visitor = {
        name: parsedVisitor[0],
        email: parsedVisitor[parsedVisitor.length - 1],
        companyName: !companyIsHidden ? parsedVisitor[1] : undefined,
      };

      currentLength += 1;
      addField(visitor);
    });

    dispatch(change('Booking', 'pastedVisitors', ''));
    return toggleModal();
  };

  return (
    <>
      {fields.map((member, index) => (
        <Wrapper key={member} index={index}>
          {index > 0 && (
            <RemoveIconWrapper onClick={() => removeField(index)}>
              ╳
            </RemoveIconWrapper>
          )}
          <Row alignItems="center" mb="15px">
            <Col>
              <Field
                component={InputWithLabel}
                validate={[required]}
                name={`${member}.name`}
                labelLeft={
                  <span>
                    <FormattedMessage {...messages.fullName} />
                    <RequiredMark />
                  </span>
                }
                placeholder=""
              />
            </Col>
          </Row>
          {!companyIsHidden && (
            <Row alignItems="center" mb="15px">
              <Col>
                <Field
                  component={InputWithLabel}
                  validate={companyIsRequired ? [required] : []}
                  name={`${member}.companyName`}
                  labelLeft={
                    <span>
                      <FormattedMessage {...messages.companyName} />
                      {companyIsRequired && (
                        <>
                          <RequiredMark />
                          <span style={{ fontSize: '12px' }}>
                            <FormattedMessage {...messages.companyNameHint} />
                          </span>
                        </>
                      )}
                    </span>
                  }
                  placeholder=""
                />
              </Col>
            </Row>
          )}
          <Row alignItems="center" mb="15px">
            <Col>
              <Field
                component={InputWithLabel}
                validate={[required, email]}
                name={`${member}.email`}
                labelLeft={
                  <span>
                    <FormattedMessage {...messages.mailAddress} />
                    <RequiredMark />
                  </span>
                }
                placeholder=""
              />
            </Col>
          </Row>
        </Wrapper>
      ))}
      {multipleguestsAllowed && (
        <>
          {fields.length >= 50 && (
            <Row>
              <Col>
                <ErrorWrapper>
                  <FormattedMessage {...messages.errMsg} />
                </ErrorWrapper>
              </Col>
            </Row>
          )}
          <Row mb={isMobile ? 20 : 0}>
            <Col>
              <AddParticipantButton
                disabled={fields.length >= 50}
                onClick={() => addField()}
              >
                <FormattedMessage {...messages.addBtn} />
              </AddParticipantButton>
            </Col>
          </Row>
          {!isMobile && (
            <Row mb={20}>
              <Col>
                <BatchAddButton
                  disabled={fields.length >= 50}
                  onClick={() => toggleModal()}
                >
                  <FormattedMessage {...messages.batchAddBtn} />
                </BatchAddButton>
              </Col>
            </Row>
          )}
        </>
      )}
      <BatchVisitorAddModal
        isOpen={isOpen}
        toggleModal={toggleModal}
        companyIsHidden={companyIsHidden}
        batchParseError={batchParseError}
        handleParseVisitors={handleParseVisitors}
      />
    </>
  );
}

VisitorFieldsGroup.defaultProps = {
  pastedVisitors: null,
  multipleguestsAllowed: false,
};

VisitorFieldsGroup.propTypes = {
  fields: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  companyIsHidden: PropTypes.bool.isRequired,
  companyIsRequired: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  multipleguestsAllowed: PropTypes.bool,
  pastedVisitors: PropTypes.string,
};

export default VisitorFieldsGroup;
