import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  formValueSelector,
  reduxForm,
  Field,
  FieldArray,
  FormSection,
  destroy,
} from 'redux-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Checkbox } from '@d-lighted/design-system';
import { cloneDeep, find, sortBy } from 'lodash';

import { useValidation } from 'utils/validation';
import { _post } from 'utils/api';
import { pushEvent, events } from 'utils/GTM';
import { Heading2 } from 'components/elements/Typography';
import DateTimeCard from 'components/forms/DateTimeCard';
import Button from 'components/forms/Button';
import Loader from 'components/layouts/Loader';
import Footer from 'components/layouts/Footer';
import {
  defaultRowMargin,
  defaultColPadding,
  Col,
  Row,
  StyledLink,
  Div,
} from 'components/utils/Helper';
import { isMobileDevice } from 'utils/mobileUtils';

import CheckBoxCase from '../../confirmation/modules/CheckBoxCase';
import RadioButtonCase from '../../confirmation/modules/RadioButton';
import TextAreaCase from '../../confirmation/modules/TextArea';
import TextFieldCase from '../../confirmation/modules/TextField';
import DropdownCase from '../../confirmation/modules/Dropdown';
import BlockOfTextCase from '../../confirmation/modules/BlockOfText';
import VisitorFieldsGroup from '../../confirmation/modules/VisitorFieldsGroup';
import { FormatCustomField } from '../../confirmation/utils/dataFormatter';
import { generateCandidateTimeslots } from '../utils/timeslotUtils';
import messages from './i18n/confirmation';

function Confirmation(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const params = useParams();
  const { selectedHours, history, handleSubmit } = props;
  const {
    checked,
    required,
    email,
    phoneNo,
    requiredCheckboxes,
    maxLength255,
  } = useValidation();

  const {
    bookingCalendarCustomFields,
    companyIsRequired,
    companyIsHidden,
    companyName,
    privacyPolicyUrl,
    useOwnPrivacyPolicy,
  } = useSelector(state => state.calendarDetail);

  const { timezone } = useSelector(state => state.timezone);
  const { slots } = useSelector(state => state.selectedSlots);

  let customFields = cloneDeep(bookingCalendarCustomFields) || [];
  customFields = sortBy(customFields, [
    function comparator(field) {
      return field.rowOrder;
    },
  ]);

  useEffect(() => {
    window.scroll(0, 0);
    pushEvent({ ...events.triggerPageView('Form') });
  }, []);

  const { year, month, date, day, time, nextHour } = selectedHours || {
    year: '202?',
    month: '??',
    date: '??',
    day: '??',
    time: '??:??',
    nextHour: '??:??',
  };

  const showCustomPrivacyPolicy = privacyPolicyUrl && useOwnPrivacyPolicy;
  const defaultPrivacyPolicy = 'https://help.receptionist.jp/?p=402';

  const registerClickLinkHelpAction = () => {
    const param = showCustomPrivacyPolicy ? '/?p=402' : 'custom';
    pushEvent({
      ...events.onClickLinkHelp(param),
    });
  };

  const getHiddenFieldValues = () => {
    const hiddenFields = customFields.filter(
      field => field.fieldType === 'hidden_field',
    );
    const result = hiddenFields.map(field =>
      FormatCustomField({
        fieldType: field.fieldType,
        uid: field.uid,
        labelName: field.labelName,
        value: field.bookingCalendarCustomFieldInputs[0]?.inputValue,
      }),
    );
    return result;
  };

  const onSubmit = async values => {
    let formattedCustomFieldData = [];
    if (values.customFields) {
      formattedCustomFieldData = Object.keys(values.customFields).flatMap(
        key => {
          const field = find(customFields, { uid: key });
          if (field.fieldType === 'check_box') {
            return values.customFields[key].map(value => {
              return FormatCustomField({
                fieldType: field.fieldType,
                uid: key,
                labelName: field.labelName,
                value,
              });
            });
          }
          if (field.fieldType === 'dropdown') {
            return FormatCustomField({
              fieldType: field.fieldType,
              uid: key,
              labelName: field.labelName,
              value: values.customFields[key].value,
            });
          }
          return FormatCustomField({
            fieldType: field.fieldType,
            uid: key,
            labelName: field.labelName,
            value: values.customFields[key],
          });
        },
      );
    }
    formattedCustomFieldData = formattedCustomFieldData.concat(
      getHiddenFieldValues(),
    );
    setLoading(true);

    document.getElementsByClassName('submit_button')[0].disabled = true;

    const dataForApi = {
      candidate_time_slots: generateCandidateTimeslots(slots, timezone.code),
      name: values.visitors[0].name,
      company: values.visitors[0].companyName || '',
      email: values.visitors[0].email,
      time_zone: timezone.code,
      booking_calendar_custom_field_inputs: formattedCustomFieldData,
    };

    _post(
      `/booking_calendars/${params.calendar_alias}/multiguest/${params.multiguest_uid}/guests`,
      dataForApi,
    ).then(response => {
      dispatch(destroy('multiguest'));
      setLoading(false);

      if (response.status < 300) {
        history.replace({
          pathname: `/${params.calendar_alias}/multiguest/${params.multiguest_uid}/${params.duration}/completed`,
          state: { ...values },
        });
      } else {
        history.replace({
          pathname: '/error',
          state: { ...values, message: response.data.message, back: true },
        });
      }
    });
  };

  const handleGoBackToForm = e => {
    e.preventDefault();
    pushEvent({ ...events.goBackToForm() });
    props.history.goBack();
  };

  if (loading) return <Loader />;

  const isMobile = isMobileDevice();

  return (
    <>
      <Row mt="30px">
        <Col justifyContent="center" display="inline-flex">
          <form>
            <Row mb="1rem" ml={['0px', defaultRowMargin]}>
              <Col pb={['12px', '25px']} pl={['0px', defaultColPadding]}>
                <Div textAlign={['left', 'center']}>
                  <Heading2>
                    <FormattedMessage {...messages.confirmationDetails} />
                  </Heading2>
                </Div>
              </Col>
            </Row>
            <Row alignItems="center" mb="25px">
              <DateTimeCard
                year={year}
                month={month}
                date={date}
                day={day}
                time={time}
                nextHour={nextHour}
                label={intl.formatMessage(messages.selectedCandidateDateTime)}
                timezone={timezone}
                isMultiTime
                slots={slots}
              />
            </Row>
            <FieldArray
              name="visitors"
              companyIsRequired={companyIsRequired}
              companyIsHidden={companyIsHidden}
              isMobile={isMobile}
              component={VisitorFieldsGroup}
            />
            <FormSection name="customFields">
              {customFields.map((field, index) => {
                let validateArray = [];
                switch (
                  field.bookingCalendarSettingBookingCalendarCustomField
                    .textFieldInputRestriction
                ) {
                  case 'email':
                    validateArray = [email];
                    break;
                  case 'phone_no':
                    validateArray = [phoneNo];
                    break;
                  default:
                    validateArray = [];
                    break;
                }
                const requiredValidates = [];
                if (
                  field.bookingCalendarSettingBookingCalendarCustomField
                    ?.inputRequired
                ) {
                  requiredValidates.push(
                    field.fieldType === 'check_box'
                      ? requiredCheckboxes
                      : required,
                  );
                }
                validateArray = [...requiredValidates, ...validateArray];
                switch (field.fieldType) {
                  case 'text_field': {
                    return (
                      <TextFieldCase
                        key={`${field.uid}`}
                        id={`${field.uid}-${index}`}
                        field={field}
                        validateArray={[...validateArray, maxLength255]}
                        isRequired={
                          field.bookingCalendarSettingBookingCalendarCustomField
                            .inputRequired
                        }
                      />
                    );
                  }
                  case 'text_area': {
                    return (
                      <TextAreaCase
                        key={`${field.uid}`}
                        id={`${field.uid}-${index}`}
                        field={field}
                        validateArray={validateArray}
                        isRequired={
                          field.bookingCalendarSettingBookingCalendarCustomField
                            .inputRequired
                        }
                      />
                    );
                  }
                  case 'radio_button': {
                    return (
                      <RadioButtonCase
                        key={`${field.uid}`}
                        id={`${field.uid}-${index}`}
                        field={field}
                        validateArray={validateArray}
                        isRequired={
                          field.bookingCalendarSettingBookingCalendarCustomField
                            .inputRequired
                        }
                      />
                    );
                  }
                  case 'check_box': {
                    return (
                      <CheckBoxCase
                        key={`${field.uid}`}
                        id={`${field.uid}-${index}`}
                        field={field}
                        validateArray={validateArray}
                        isRequired={
                          field.bookingCalendarSettingBookingCalendarCustomField
                            .inputRequired
                        }
                      />
                    );
                  }
                  case 'dropdown': {
                    return (
                      <DropdownCase
                        key={`${field.uid}`}
                        id={`${field.uid}-${index}`}
                        field={field}
                        validateArray={validateArray}
                        isRequired={
                          field.bookingCalendarSettingBookingCalendarCustomField
                            .inputRequired
                        }
                      />
                    );
                  }
                  case 'block_of_text': {
                    return (
                      <BlockOfTextCase key={`${field.uid}`} field={field} />
                    );
                  }
                  default: {
                    return null;
                  }
                }
              })}
            </FormSection>
            <Row mb="25px">
              <Col
                display={[null, 'flex']}
                justifyContent={[null, 'center']}
                width={[null, '100%']}
                minWidth={[null, '376px']}
              >
                <div>
                  <Field
                    useArrayValue={false}
                    trueFor="agree"
                    component={Checkbox}
                    id="agree"
                    name="agree"
                    validate={[checked]}
                  >
                    {showCustomPrivacyPolicy ? (
                      <FormattedMessage
                        {...messages.customPrivacyPolicy}
                        values={{
                          br: <br />,
                          c: companyName,
                          a: function userPrivacyLink(chunks) {
                            return (
                              <StyledLink
                                tabIndex="-1"
                                href={privacyPolicyUrl}
                                target="_blank"
                                color="#00bbb5"
                                onClick={() => registerClickLinkHelpAction()}
                              >
                                {chunks}
                              </StyledLink>
                            );
                          },
                          aa: function productPrivacyLink(chunks) {
                            return (
                              <StyledLink
                                tabIndex="-1"
                                href={defaultPrivacyPolicy}
                                target="_blank"
                                color="#00bbb5"
                                onClick={() => registerClickLinkHelpAction()}
                              >
                                {chunks}
                              </StyledLink>
                            );
                          },
                        }}
                      />
                    ) : (
                      <FormattedMessage
                        {...messages.privacyPolicy}
                        values={{
                          a: function defaultPrivacyLink(chunks) {
                            return (
                              <StyledLink
                                tabIndex="-1"
                                href={defaultPrivacyPolicy}
                                target="_blank"
                                color="#00bbb5"
                                onClick={() => registerClickLinkHelpAction()}
                              >
                                {chunks}
                              </StyledLink>
                            );
                          },
                        }}
                      />
                    )}
                  </Field>
                </div>
              </Col>
            </Row>
            <Row display={['block', 'none']} mb="1rem">
              <Col>
                <Button
                  type="submit"
                  outlined
                  width="100%"
                  onClick={e => handleGoBackToForm(e)}
                >
                  <FormattedMessage {...messages.reselectBtn} />
                </Button>
              </Col>
            </Row>
            <Div
              width={[null, '100%']}
              minWidth={[null, '376px']}
              mt={[null, '19px']}
              justifyContent={[null, 'center']}
              alignItems={[null, 'center']}
              display={[null, 'flex']}
              mx={[null, '-18px']}
            >
              <Div display={['none', 'block']} pr={3}>
                <Button
                  outlined
                  width="149px"
                  onClick={e => handleGoBackToForm(e)}
                >
                  <FormattedMessage {...messages.reselectBtn} />
                </Button>
              </Div>
              <Button
                className="submit_button"
                width={['100%', '211px']}
                onClick={handleSubmit(values => onSubmit(values))}
              >
                <FormattedMessage {...messages.submit} />
              </Button>
            </Div>
          </form>
        </Col>
      </Row>
      <Footer />
    </>
  );
}

const ConfirmationPage = connect(state => {
  const selector = formValueSelector('BookingDate');
  return {
    selectedHours: selector(state, 'selectedHours'),
  };
})(
  reduxForm({
    form: 'Booking',
    initialValues: { visitors: [{}], agree: false },
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
  })(Confirmation),
);

export default ConfirmationPage;

Confirmation.defaultProps = {
  selectedHours: {},
};

Confirmation.propTypes = {
  selectedHours: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  history: ReactRouterPropTypes.history.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};
