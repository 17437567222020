import { defineMessages } from 'react-intl';

export default defineMessages({
  batchAddTitle: {
    id: 'confirmation.form.visitorFieldsGroup.multiguest.batchAdd',
    defaultMessage: 'Collective registration of attendee information',
  },
  info1: {
    id: 'confirmation.form.visitorFieldsGroup.batchVisitorAddModal.information1',
    defaultMessage: 'Copy guests info from Excel file and',
  },
  info2: {
    id: 'confirmation.form.visitorFieldsGroup.batchVisitorAddModal.information2',
    defaultMessage: 'paste it in the text area below.',
  },
  info3: {
    id: 'confirmation.form.visitorFieldsGroup.batchVisitorAddModal.information3',
    defaultMessage: 'For details on how to use, ',
  },
  infoLink: {
    id: 'confirmation.form.visitorFieldsGroup.batchVisitorAddModal.informationLink',
    defaultMessage: 'click here',
  },
  placeholder1: {
    id: 'confirmation.form.visitorFieldsGroup.batchVisitorAddModal.textArea.placeholder1',
    defaultMessage: 'Name 1 Email Address 1 \nName 2 Email Address 2',
  },
  placeholder2: {
    id: 'confirmation.form.visitorFieldsGroup.batchVisitorAddModal.textArea.placeholder2',
    defaultMessage:
      'Name 1 Company name 1 Email address 1 \nName 2 Company name 2 Email address 2',
  },
  addBtn: {
    id: 'confirmation.form.visitorFieldsGroup.batchVisitorAddModal.addBtn',
    defaultMessage: 'ADD',
  },
});
