import React from 'react';
import { Route } from 'react-router-dom';
import { pushEvent, events } from 'utils/GTM';
import Header from './components/layouts/Header';

import { bookingRoutes } from './views/booking';
import { confirmationRoutes } from './views/confirmation';
import { multiguestRoutes } from './views/multiguest';
import { errorRoutes } from './views/error';
import { Container } from './components/utils/Helper';

function combineRoutes() {
  return [
    ...errorRoutes, // DO NOT MOVE: since irregular paths mess with error route, error should precede.
    ...bookingRoutes,
    ...confirmationRoutes,
    ...multiguestRoutes,
  ];
}

export const routeGenerator = props => {
  return combineRoutes().map(route => {
    return (
      <Route
        key={route.path}
        path={route.path}
        exact={route.exact}
        render={restProps => {
          /* *
           * This code track the page view.
           * */
          if (route.gauid) {
            pushEvent({ ...events.triggerPageView(route.gauid) });
          }
          return (
            <>
              <Header feature={route.feature} {...restProps} />
              <Container>
                <route.component
                  feature={route.feature}
                  {...restProps}
                  {...props}
                />
              </Container>
            </>
          );
        }}
      />
    );
  });
};
