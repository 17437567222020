import React, { useEffect, useRef, useState } from 'react';
import { createAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Dropdown, {
  DropdownContent,
  DropdownTrigger,
} from 'react-simple-dropdown';
import styled from 'styled-components';
import { DatePicker as SmallCalendar } from '@d-lighted/design-system';
import 'react-simple-dropdown/styles/Dropdown.css';
import { FormattedMessage } from 'react-intl';
// Local Component
import { useLocale, LOCALES } from 'hooks/useLocale';
import { Row, Col } from '../utils/Helper';
import IconButton from '../forms/IconButton';
import {
  moment,
  getMinimalTimezoneSet,
  formatTimezone,
  getDefaultTimezone,
} from '../../utils/momentUtils';
import { Heading1, Heading2, Heading3 } from '../elements/Typography';
import CalendarIcon from '../../assets/img/Icons-Content-Calendar.svg';
import TimezoneIcon from '../../assets/img/globe.svg';
import { events, pushEvent } from '../../utils/GTM';
import messages from './i18n/calendarHeader';

const TimezoneLabel = styled.div`
  position: relative;
  margin: 5px 20px 0 0;
  border-radius: 4px;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  height: 20em;
  overflow-y: scroll;
`;

const ParticipantDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 133px;
  height: 42px;
  padding: 0px 10px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
  font-weight: 500;
  color: #00bbb5;

  &:hover {
    background-color: #00bbb510;
  }
`;

const ParticipantDropdown = styled.div`
  margin-top: 8px;
  width: 255px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #e5e5e5;
  position: absolute;
  z-index: 1;
`;

const ParticipantItem = styled.a`
  color: #314143;
  padding: 12px 16px;
  font-size: 15px;
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: left;
  cursor: pointer;
  &:hover {
    background-color: #e6f4ef;
  }
`;

const GuestHeader = styled.span`
  display: block;
  height: 19px;
  padding: 12px 16px;
  font-family: NotoSansJP;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #68878d;
  margin-bottom: 20px;
`;

const DropDownItem = styled.a`
  color: #314143;
  padding: 12px 16px;
  font-size: 15px;
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: left;
  cursor: pointer;
  &:hover {
    background-color: #e6f4ef;
  }
`;

const MediaIconButton = styled(IconButton)`
  order: ${props => props.order};
`;

const MediaColumn = styled(Col)`
  order: 2;

  @media screen and (min-width: 992px) {
    order: 2 !important;
  }
  @media screen and (min-width: 576px) {
    order: 5;
  }
`;

const MediaWrapper = styled.div`
  order: ${props => props.order};
`;

const CalendarWrapper = styled.div`
  .dropdown__content {
    left: auto;
    right: 0px;
    z-index: 1;
  }
`;
export function CalendarHeader({
  options,
  getPrevWeek,
  getNextWeek,
  isTimeSlotLoading,
  timeSlotsHasError,
  children,
  setDateFromSmallCalendar,
  isMultiTime,
  multiguestCalendarDetail,
  getSelectedTimezone,
}) {
  const {
    currentWeek: { week, selectedDate },
  } = options;

  const dropdown = useRef(null);
  const participant = useRef(null);
  const [dropdownActive, setDropdownActive] = useState(false);
  const [participantActive, setParticipantActive] = useState(false);
  const timezoneSelection = useRef(null);
  const dispatch = useDispatch();
  const [timezone, setTimezone] = useState({
    label: formatTimezone(),
    code: getDefaultTimezone(),
  });
  const [timezoneDropdownStatus, setTimezoneDropdownStatus] = useState(false);
  const [locale] = useLocale();
  const updateTimezoneAction = createAction('timezone_update');
  let startDate = '';

  function useClickOutsideDropdown(ref, value) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          switch (value) {
            case 'dropdown':
              setDropdownActive(false);
              break;
            case 'participant':
              setParticipantActive(false);
              break;
            default:
              break;
          }
        }
      }

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref, value]);
  }

  function useClickOutsideTimezoneDropdown(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setTimezoneDropdownStatus(false);
        }
      }

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(() => {
    dispatch(
      updateTimezoneAction({
        timezone: { label: timezone.label, code: timezone.code },
      }),
    );
  }, [dispatch, updateTimezoneAction, timezone]);

  useClickOutsideDropdown(dropdown, 'dropdown');
  useClickOutsideDropdown(participant, 'participant');
  useClickOutsideTimezoneDropdown(timezoneSelection);

  const handleCalendarIconClick = () => {
    if (!dropdownActive) {
      pushEvent({ ...events.calendarIconClick() });
    }
    setDropdownActive(!dropdownActive);
  };

  const registerEventCalendarMonthSelection = event => {
    if (startDate) {
      if (event.activeStartDate > startDate) {
        pushEvent({ ...events.changeMonth('next') });
      } else {
        pushEvent({ ...events.changeMonth('previous') });
      }
    } else {
      pushEvent({ ...events.changeMonth('next') });
    }
    startDate = event.activeStartDate;
  };

  return (
    <>
      {!timeSlotsHasError && !isTimeSlotLoading && (
        <Row mt={['10px', '15px']} pl={[null, '15px']}>
          <Col col>
            <Row width="max-content" mb="10px">
              <MediaIconButton
                borderRadius="0px"
                fontSize="14px"
                width="max-content"
                display="flex"
                alignItems="center"
                marginRight={['0px', '10px', '0px']}
                disabled={
                  isTimeSlotLoading || moment(week[0]).isBefore(moment())
                }
                onClick={getPrevWeek}
                order={1}
              >
                <i
                  style={{ margin: '0 4px 0 0' }}
                  className="fa fa-angle-left fa-2x"
                  aria-hidden="true"
                />
                <FormattedMessage {...messages.previousWeek} />
              </MediaIconButton>
              <MediaColumn width="max-content" alignItems="center">
                <Heading1 display="block">
                  {selectedDate.year()}
                  {/* '年' can't be directly provided by moment's JA locale. Refer:-https://github.com/moment/moment/issues/4211 */}
                  {locale === LOCALES.JA && '年'}
                </Heading1>
                <Heading2 width="100%" display="block">
                  {week[0].format('M/D')} -{' '}
                  {week[week.length - 1].format('M/D')}
                </Heading2>
              </MediaColumn>
              <MediaIconButton
                borderRadius="0px"
                fontSize="14px"
                width="max-content"
                display="flex"
                alignItems="center"
                disabled={isTimeSlotLoading}
                onClick={getNextWeek}
                order={3}
              >
                <FormattedMessage {...messages.nextWeek} />
                <i
                  style={{ margin: '0 0 0 4px' }}
                  className="fa fa-angle-right fa-2x"
                  aria-hidden="true"
                />
              </MediaIconButton>
              <MediaWrapper ref={dropdown} order={4}>
                <Dropdown disabled={isTimeSlotLoading} active={dropdownActive}>
                  <DropdownTrigger>
                    <IconButton
                      borderRadius="0px"
                      marginLeft="15px"
                      disabled={isTimeSlotLoading}
                      onClick={() => handleCalendarIconClick()}
                    >
                      <img src={CalendarIcon} alt="Favicon" />
                    </IconButton>
                  </DropdownTrigger>
                  <CalendarWrapper>
                    <DropdownContent>
                      <SmallCalendar
                        locale={locale}
                        showActionButton={false}
                        onChange={value => {
                          setDateFromSmallCalendar(value);
                          setTimeout(() => setDropdownActive(false), 100);
                        }}
                        onActiveStartDateChange={event =>
                          registerEventCalendarMonthSelection(event)
                        }
                      />
                    </DropdownContent>
                  </CalendarWrapper>
                </Dropdown>
              </MediaWrapper>
            </Row>
            <Row>
              <Col mb={['5px', '12px']}>
                <Heading3>
                  <FormattedMessage {...messages.selectDateTime} />
                </Heading3>
              </Col>
            </Row>
          </Col>
          <Col
            col
            display={['none', 'flex']}
            justifyContent="flex-end"
            pr="20px"
          >
            {isMultiTime && (
              <div ref={participant}>
                <Dropdown
                  disabled={isTimeSlotLoading}
                  active={participantActive}
                >
                  <DropdownTrigger>
                    <ParticipantDiv
                      onClick={() => {
                        setParticipantActive(!participantActive);
                      }}
                    >
                      <FormattedMessage {...messages.participantList} />
                      <i className="fa fa-caret-down" aria-hidden="true" />
                    </ParticipantDiv>
                  </DropdownTrigger>
                  <DropdownContent>
                    {multiguestCalendarDetail.guests &&
                      multiguestCalendarDetail.guests.length !== 0 && (
                        <ParticipantDropdown>
                          <GuestHeader>
                            <FormattedMessage {...messages.customerDate} />
                          </GuestHeader>
                          {multiguestCalendarDetail.guests?.map(guest => (
                            <ParticipantItem key={guest.id}>
                              {guest.companyName
                                ? `${guest.name} - ${guest.companyName}`
                                : guest.name}
                            </ParticipantItem>
                          ))}
                        </ParticipantDropdown>
                      )}
                  </DropdownContent>
                </Dropdown>
              </div>
            )}

            <div ref={timezoneSelection}>
              <Dropdown
                disabled={isTimeSlotLoading}
                active={timezoneDropdownStatus}
              >
                <DropdownTrigger>
                  <IconButton
                    width="auto"
                    fontSize="14px"
                    padding="10px 15px"
                    disabled={isTimeSlotLoading || isMultiTime}
                    onClick={() => {
                      setTimezoneDropdownStatus(!timezoneDropdownStatus);
                    }}
                  >
                    <img
                      src={TimezoneIcon}
                      alt="timezone"
                      style={{ marginRight: '10px', verticalAlign: 'bottom' }}
                    />
                    {timezone.label}
                  </IconButton>
                </DropdownTrigger>
                <DropdownContent>
                  <TimezoneLabel>
                    {getMinimalTimezoneSet().map(zone => {
                      return (
                        <DropDownItem
                          key={zone.tzCode}
                          onClick={() => {
                            setTimezone(prevState => ({
                              ...prevState,
                              label: zone.label,
                              code: zone.tzCode,
                            }));
                            setTimezoneDropdownStatus(false);
                            return getSelectedTimezone(zone.tzCode);
                          }}
                        >
                          {zone.label}
                        </DropDownItem>
                      );
                    })}
                  </TimezoneLabel>
                </DropdownContent>
              </Dropdown>
            </div>
          </Col>
        </Row>
      )}
      <Row>
        <Col padding="0px">{children}</Col>
      </Row>
    </>
  );
}

CalendarHeader.defaultProps = {
  options: {},
  isTimeSlotLoading: false,
  timeSlotsHasError: false,
  children: null,
  getSelectedTimezone: () => {},
  isMultiTime: false,
  multiguestCalendarDetail: [],
};

CalendarHeader.propTypes = {
  options: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  isTimeSlotLoading: PropTypes.bool,
  timeSlotsHasError: PropTypes.bool,
  getPrevWeek: PropTypes.func.isRequired,
  getNextWeek: PropTypes.func.isRequired,
  setDateFromSmallCalendar: PropTypes.func.isRequired,
  children: PropTypes.node,
  getSelectedTimezone: PropTypes.func,
  isMultiTime: PropTypes.bool,
  multiguestCalendarDetail: PropTypes.instanceOf(Array),
};
