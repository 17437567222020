import { defineMessages } from 'react-intl';

export default defineMessages({
  persons: {
    id: 'booking.bigCalendar.calendarBody.timeSlots.persons',
    defaultMessage: '{guests}persons',
  },
  person: {
    id: 'booking.bigCalendar.calendarBody.timeSlots.person',
    defaultMessage: '{guests}person',
  },
  availableParticipants: {
    id: 'booking.bigCalendar.calendarBody.timeSlots.availableParticipants',
    defaultMessage: 'Available participants',
  },
});
