import React, { useEffect } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { createAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

/** Local Import */
import { isPageTypeIrregular } from 'utils/calendarUtils';
import {
  Heading2,
  // Heading4,
} from '../../../components/elements/Typography';
import DateTimeCard from '../../../components/forms/DateTimeCard';
import { Div, StyledLink } from '../../../components/utils/Helper';
import Footer from '../../../components/layouts/Footer';
import Button from '../../../components/forms/Button';
import artCompleted from '../../../assets/img/art-completed.svg';
import googleIcon from '../../../assets/img/google_icon.svg';
import outlookIcon from '../../../assets/img/outlook_icon.svg';
import { pushEvent, events } from '../../../utils/GTM';
import messages from './i18n/complete';

const StyledImage = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  position: relative;
  top: 5px;
`;

const StyledSpan = styled.span`
  opacity: 0.6;
  font-size: 15px;
  line-height: 1.6;
  color: #314143;
`;

function isMobileDevice() {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
}

const ConfirmationMessage = styled.div`
  font-size: 15px;
  font-weight: 200;
  line-height: 1.6;
  margin-top: 10px;
  white-space: pre-line;
`;

const CompletePage = props => {
  const params = useParams();
  const dispatch = useDispatch();
  const { timezone } = useSelector(state => state.timezone);
  const calendarDetail = useSelector(state => state.calendarDetail);
  const isIrregular = isPageTypeIrregular(calendarDetail);
  const { location } = props;

  useEffect(() => {
    if (location?.state?.selectedHours) {
      pushEvent({ ...events.triggerPageView('Completed') });
    }

    if (!location?.state?.selectedHours || !calendarDetail) {
      props.history.push(
        `/${params.calendar_alias}${
          params.duration ? `/${params.duration}` : ''
        }`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, params]);

  dispatch(createAction('timeSlots_reset')({}));

  if (!location?.state) return null;

  const { message, selectedHours } = location.state;
  const { year, month, date, day, time, nextHour, appointment } =
    (location.state && selectedHours) || {
      year: undefined,
      month: undefined,
      date: undefined,
      day: undefined,
      time: undefined,
      nextHour: undefined,
      appointment: {},
    };

  const registerSchedulingAppLinkClick = () => {
    pushEvent({
      ...events.onClickSchedulingAppLink('/'),
    });
  };

  const registerClickLinkCalendarAction = (path, calendar) => {
    pushEvent({ ...events.onClickCalendarLinkTags(path, calendar) });
  };

  return (
    <>
      <Div textAlign="center" mt="30px">
        <Heading2>
          {isIrregular ? (
            <FormattedMessage {...messages.titleIrregular} />
          ) : (
            <FormattedMessage {...messages.title} />
          )}
        </Heading2>
        {message && <ConfirmationMessage>{message}</ConfirmationMessage>}
        <Div display="flex" justifyContent="center">
          <DateTimeCard
            appointment={appointment}
            year={year}
            month={month}
            date={date}
            day={day}
            time={time}
            nextHour={nextHour}
            timezone={timezone}
            centerItems
            width="300px"
            mt="15px"
            mb="25px"
            isIrregular={isIrregular}
          />
        </Div>
        <Div display="flex" justifyContent="center" mb="25px">
          <img
            width="200px"
            height="150px"
            src={artCompleted}
            alt="Completed"
          />
        </Div>
        {!isIrregular && (
          <>
            <Div display="flex" justifyContent="center">
              <a
                href={
                  location.state.appointment?.appointment_google_calendar_url
                }
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  registerClickLinkCalendarAction(
                    props.location.state.appointment
                      ?.appointment_google_calendar_url,
                    'google',
                  )
                }
              >
                <Button outlined mb="10px">
                  <StyledImage src={googleIcon} />
                  <FormattedMessage {...messages.googleBtn} />
                </Button>
              </a>
            </Div>
            {!isMobileDevice() && (
              <Div>
                <Div display="flex" justifyContent="center">
                  <a
                    href={
                      location.state.appointment
                        ?.appointment_outlook_calendar_url
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() =>
                      registerClickLinkCalendarAction(
                        props.location.state.appointment
                          ?.appointment_outlook_calendar_url,
                        'outlook',
                      )
                    }
                  >
                    <Button outlined mb="5px">
                      <StyledImage src={outlookIcon} />
                      <FormattedMessage {...messages.outlookBtn} />
                    </Button>
                  </a>
                </Div>
                <StyledSpan>
                  <FormattedMessage {...messages.notice} />
                </StyledSpan>
              </Div>
            )}
          </>
        )}
      </Div>
      <Div display="flex" justifyContent="center">
        <StyledLink
          mt="20px"
          color="#00bbb5"
          href="https://scheduling.receptionist.jp/"
          onClick={() => registerSchedulingAppLinkClick()}
        >
          <FormattedMessage {...messages.scheduleLink} />
        </StyledLink>
      </Div>
      <Footer mt="45px" />
    </>
  );
};

export default CompletePage;

CompletePage.propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};
