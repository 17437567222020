import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import humps from 'lodash-humps';
import { useParams } from 'react-router-dom';

import { _get } from 'utils/api';
import {
  updateTimeSlots,
  setNotFirstTime,
  resetTimeSlots as reset,
} from 'redux/reducers/timeSlotsReducer';
import { getDefaultTimezone, moment } from 'utils/momentUtils';
import { checkStatus } from 'utils/apiUtils';
import { debounce } from 'lodash';

function useAvailableTimeSlots(uid, duration, timeZone = getDefaultTimezone()) {
  const availableTimeSlots = useSelector(state => state.timeSlots);
  const [startOfWeek, setStartOfWeek] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();

  const originalFetchAvailableTimeSlots = (timeMin, timeMax) => {
    const queryParams = {
      duration,
      time_min: timeMin,
      time_max: timeMax,
      time_zone: timeZone,
    };

    if (availableTimeSlots.firstTime) {
      queryParams.next_available_week = true;
      dispatch(setNotFirstTime());
    }
    const queryString = new URLSearchParams(queryParams);

    const url = `booking_calendars/${
      params.calendar_alias
    }/available_time_slots?${queryString.toString()}&multiguest=true`;
    _get(url)
      .then(result => {
        const { data, status } = result;

        const payload = {
          ...humps(data),
          status,
          url,
          ...checkStatus(status),
        };
        if (data.time_range != null) {
          setStartOfWeek(moment(data.time_range.start_time));
        }
        dispatch(updateTimeSlots(payload));
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchAvailableTimeSlots = useCallback(
    debounce(
      (timeMin, timeMax) => originalFetchAvailableTimeSlots(timeMin, timeMax),
      500,
    ),
    [],
  );

  const resetTimeSlots = () => {
    dispatch(reset());
  };

  return {
    availableTimeSlots,
    isLoading,
    startOfWeek,
    actions: {
      fetchAvailableTimeSlots,
      resetTimeSlots,
    },
  };
}

export { useAvailableTimeSlots };
