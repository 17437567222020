import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATUS = {
  isSuccess: false,
  hasError: false,
  firstTime: true,
  availableTimeSlots: [],
};

export const setNotFirstTime = createAction('timeSlots_notFirstTime');

export const updateTimeSlots = createAction('timeSlots_update');

export const resetTimeSlots = createAction('timeSlots_reset');

const timeSlotsReducer = createReducer(
  { ...INITIAL_STATUS },
  {
    timeSlots_update: (state, action) => {
      return { ...action.payload, firstTime: false };
    },
    timeSlots_reset: () => {
      return { ...INITIAL_STATUS, firstTime: false };
    },
    timeSlots_notFirstTime: state => {
      return { ...state, firstTime: false };
    },
  },
);

export default timeSlotsReducer;
