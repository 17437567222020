import { defineMessages } from 'react-intl';

export default defineMessages({
  instruction1: {
    id: 'confirmation.form.visitorFieldsGroup.instruction1',
    defaultMessage: 'Each line should have name and email address.',
  },
  instruction2: {
    id: 'confirmation.form.visitorFieldsGroup.instruction2',
    defaultMessage:
      'Each line should have name, company name and email address.',
  },
  fullName: {
    id: 'confirmation.form.visitorFieldsGroup.fullName',
    defaultMessage: 'Full Name',
  },
  companyName: {
    id: 'confirmation.form.visitorFieldsGroup.companyName',
    defaultMessage: 'Company Name',
  },
  companyNameHint: {
    id: 'confirmation.form.visitorFieldsGroup.companyName.hint',
    defaultMessage: '(If you are an individual, please enter "individual")',
  },
  mailAddress: {
    id: 'confirmation.form.visitorFieldsGroup.mailAddress',
    defaultMessage: 'Email Address',
  },
  errMsg: {
    id: 'confirmation.form.visitorFieldsGroup.multiguest.error',
    defaultMessage: 'You have reached the maximum number you can add.',
  },
  addBtn: {
    id: 'confirmation.form.visitorFieldsGroup.multiguest.addBtn',
    defaultMessage: 'Add more guests',
  },
  batchAddBtn: {
    id: 'confirmation.form.visitorFieldsGroup.multiguest.batchAdd',
    defaultMessage: 'Bulk add guests information',
  },
});
